@font-face {
  font-family: "caslon_antiqueregular";
  src: url("./fonts/caslonantique-webfont.woff2") format("woff2"), url("./fonts/caslonantique-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "caslon_antiquebold";
  src: url("./fonts/caslonantique-bold-webfont.woff2") format("woff2"), url("./fonts/caslonantique-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

body {
  font: 14px "Open Sans", sans-serif;
  background: url("./images/background.jpg");
  background-size: cover;
  color: #fff;
  font-weight: 300;
  height: 100vh; }

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #b29871;
  font-family: "caslon_antiqueregular", serif;
  font-size: 2.5em;
  font-weight: 500;
  text-transform: uppercase; }

h2 {
  font-size: 1.8em; }

h3 {
  font-size: 1.6em; }

h4 {
  font-size: 1.2em; }

a {
  color: #fff;
  font-weight: bold;
  text-decoration: none; }

a:hover {
  text-decoration: underline; }

a.active,
.menu a.active {
  color: #19191b;
  background: #b29871; }

#root {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0; }

.App {
  display: flex;
  flex-direction: column;
  height: 100vh !important;
  width: 100vw !important;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0; }

.Header {
  flex-grow: 0;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between; }

.Content {
  flex-grow: 1;
  position: relative;
  overflow: auto; }

.Footer {
  flex-grow: 0;
  position: relative;
  display: flex; }

.important {
  color: white;
  padding: 2px;
  background: red; }

.AdSense {
  flex-grow: 0;
  overflow: hidden; }

.banner {
  min-width: 400px;
  max-width: 970px;
  display: none; }

@media (min-width: 400px) and (min-height: 700px) and (orientation: landscape) {
  .banner {
    width: calc(100%);
    max-height: 100px;
    height: 100px; }
  .banner1 {
    display: inline-block; } }

@media (min-width: 970px) and (min-height: 700px) and (orientation: landscape) {
  .banner {
    width: calc(50%);
    max-height: 280px;
    height: 280px; }
  .banner1,
  .banner2 {
    display: inline-block; } }

@media (min-width: 2340px) and (min-height: 700px) and (orientation: landscape) {
  .banner {
    max-height: 280px;
    height: 280px;
    display: inline-block; }
  .banner1,
  .banner3 {
    width: 970px; }
  .banner2 {
    width: calc(100% - 1940px); } }

button,
select {
  cursor: pointer; }

.btn {
  flex-grow: 0;
  flex-shrink: 0;
  padding: 2px;
  color: #a09d9a;
  background: url("https://res.cloudinary.com/fishofstone/image/upload/f_auto,q_auto:low/twwstats/api/7020588859072995689/ui/skins/default/button_basic_pressed.webp");
  display: inline-block;
  padding: 10px 8px;
  height: 20px;
  width: 24px;
  background-size: contain;
  line-height: 20px;
  text-align: center;
  font-size: 22px;
  cursor: pointer;
  margin: 2px; }

.btn img {
  opacity: 0.6; }

.btn:hover {
  color: #fff;
  background: url("https://res.cloudinary.com/fishofstone/image/upload/f_auto,q_auto:low/twwstats/api/7020588859072995689/ui/skins/default/button_basic_selected.webp");
  background-size: contain; }

.btn:hover img {
  opacity: 1; }

.generic-button {
  width: 366px;
  height: 60px;
  padding: 2px;
  background: url("https://res.cloudinary.com/fishofstone/image/upload/f_auto,q_auto:low/twwstats/api/6009914752132233841/ui/skins/default/button_cycle_active.webp");
  display: inline-block;
  border: 0;
  background-size: contain;
  line-height: 32px;
  text-align: center;
  font-size: 20px;
  cursor: pointer;
  color: white;
  font-family: "caslon_antiqueregular", serif;
  text-transform: uppercase;
  position: relative; }

.generic-button:hover,
.generic-button:active {
  background: url("https://res.cloudinary.com/fishofstone/image/upload/f_auto,q_auto:low/twwstats/api/6009914752132233841/ui/skins/default/button_cycle_selected_hover.webp"); }

.Header .logo,
.Header h1 {
  flex-grow: 1;
  display: inline-block;
  text-align: center;
  /* padding: 4px; For logo, not for h1*/
  margin: 0px; }

div.twwstats-toast {
  color: #000;
  font-size: 18px;
  text-transform: uppercase;
  border: 1px solid #b29871;
  font-family: "Open Sans", sans-serif; }

#frontend-version {
  position: absolute;
  bottom: 0px;
  line-height: 40px;
  right: 8px;
  font-style: italic;
  color: #afa595; }

.simple-page,
.simple-dialog {
  padding: 16px;
  margin: 0px 8px 8px;
  position: relative;
  background: #19191b;
  overflow: hidden; }

.simple-dialog {
  padding-top: 58px;
  margin: 8px;
  max-height: calc(100vh - 90px - 40px - 90px);
  -moz-box-shadow: -2px 2px 5px 3px #333;
  -webkit-box-shadow: -2px 2px 5px 3px #333;
  box-shadow: -2px 2px 5px 3px #333;
  border-radius: 8px; }

.simple-dialog .dialog-content {
  max-height: calc(100vh - 90px - 40px - 90px);
  position: relative;
  overflow: auto; }

.simple-page .contour,
.simple-dialog .contour {
  background-image: url("https://res.cloudinary.com/fishofstone/image/upload/f_auto,q_auto:low/twwstats/api/7020588859072995689/ui/skins/default/panel_back_border.webp");
  background-repeat: no-repeat;
  position: absolute;
  height: 64px;
  width: 64px; }

.contour.top-left {
  top: 0;
  left: 0;
  background-position: 0 0; }

.contour.top-right {
  top: 0;
  right: 0;
  background-position: -192px 0; }

.contour.bottom-left {
  bottom: 0;
  left: 0;
  background-position: 0 -192px; }

.contour.bottom-right {
  bottom: 0;
  right: 0;
  background-position: -192px -192px; }

.contour.top {
  top: 0;
  left: 64px;
  width: calc(100% - 128px);
  background-size: calc(100% + 256px) 256px;
  background-position: -128px 0; }

.contour.bottom {
  bottom: 0;
  left: 64px;
  width: calc(100% - 128px);
  background-size: calc(100% + 256px) 256px;
  background-position: -128px -192px; }

.contour.right {
  right: 0;
  top: 64px;
  height: calc(100% - 128px);
  background-size: 256px calc(100% + 1024px);
  background-position: -192px -512px; }

.contour.left {
  left: 0;
  top: 64px;
  height: calc(100% - 128px);
  background-size: 256px calc(100% + 1024px);
  background-position: 0 -512px; }

.simple-page h1,
.simple-dialog h1 {
  margin-top: 0px; }

.simple-dialog .menu {
  overflow: hidden; }

.menu a {
  display: block;
  color: #b29871;
  font-family: "caslon_antiqueregular", serif;
  font-size: 2em;
  font-weight: 500;
  text-transform: uppercase;
  position: relative;
  margin-bottom: 12px; }

.twwstats-modal {
  position: absolute;
  top: 40px;
  left: 0px;
  border: 0;
  right: 00;
  bottom: 90px;
  background: transparent;
  outline: none;
  max-height: calc(100vh - 40px - 90px); }

.twwstats-modal.after-open,
.twwstats_unit_finder.after-open {
  top: 40px;
  opacity: 1;
  transition: top 300ms, opacity 150ms; }

.twwstats-modal.before-close,
.twwstats_unit_finder.before-close {
  top: 25%;
  opacity: 0;
  transition: top 300ms, opacity 150ms; }

.twwstats-menu {
  position: absolute;
  top: 0;
  left: -458px;
  border: 0;
  right: auto;
  bottom: auto;
  width: 450px;
  max-height: calc(100vh - 90px);
  padding: 0;
  opacity: 0;
  background: transparent;
  outline: none; }

.twwstats-menu.after-open {
  left: -8px;
  opacity: 1;
  transition: left 300ms, opacity 150ms; }

.twwstats-menu.before-close {
  left: -458px;
  opacity: 0;
  transition: left 300ms, opacity 150ms; }

.ReactModal__Overlay {
  opacity: 0; }

.ReactModal__Overlay--after-open {
  opacity: 1;
  transition: opacity 300ms; }

.ReactModal__Overlay--before-close {
  opacity: 0;
  transition: opacity 300ms; }

.ReactModal__Overlay {
  z-index: 9000; }

.flex-row {
  display: flex !important;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start; }

.flex-column {
  display: flex !important;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: flex-start; }

.accordion {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: flex-start;
  overflow: hidden;
  height: 100%;
  width: 100%; }

.accordion__item {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: flex-start;
  flex-basis: fit-content;
  min-height: 46px;
  width: 100%; }

.accordion__title {
  outline: 0;
  cursor: pointer; }

.accordion__body {
  overflow: auto;
  width: 100%; }

.accordion__body--hidden {
  display: none !important; }

.selectable_tile .selectable_tile_hover {
  display: none; }

.selectable_tile:hover .selectable_tile_hover {
  display: block; }

.confirm-button {
  width: 100%;
  position: absolute;
  bottom: 9px; }

.confirm-button button {
  outline: 0; }

.bottom_button-enter {
  opacity: 0.01;
  bottom: -60px; }

.bottom_button-enter.bottom_button-enter-active {
  opacity: 1;
  bottom: 9px;
  transition: opacity 300ms ease-in, bottom 300ms ease-out; }

.bottom_button-leave {
  opacity: 1;
  bottom: 9px; }

.bottom_button-leave.bottom_button-leave-active {
  opacity: 0.01;
  bottom: -60px;
  transition: opacity 300ms ease-in, bottom 300ms ease-in; }

.twwstats_unit_finder {
  position: absolute;
  outline: none;
  right: 0;
  left: 0; }

.twwstats_unit_finder .simple-dialog {
  height: calc(100vh - 90px - 40px - 90px); }

.twwstats_unit_finder .simple-dialog .dialog-content {
  height: calc(100vh - 90px - 40px - 90px);
  overflow: hidden; }

.parchment {
  padding: 7px 7px;
  background: url("https://res.cloudinary.com/fishofstone/image/upload/f_auto,q_auto:low,w_256,h_192,c_fill/twwstats/api/6009914752132233841/ui/skins/default/parchment_texture.webp");
  background-repeat: repeat-y;
  background-position: center;
  width: 242px;
  margin: 16px 0 0;
  min-height: 90px;
  position: relative; }

.parchment:before {
  content: "";
  position: absolute;
  top: -16px;
  left: -7px;
  height: 33px;
  width: 270px;
  background: url("https://res.cloudinary.com/fishofstone/image/upload/f_auto,q_auto:low/twwstats/api/6009914752132233841/ui/skins/default/parchment_texture_v_roll_top.webp"); }

.parchment:after {
  content: "";
  position: absolute;
  bottom: -16px;
  left: -7px;
  height: 45px;
  width: 270px;
  background: url("https://res.cloudinary.com/fishofstone/image/upload/f_auto,q_auto:low/twwstats/api/6009914752132233841/ui/skins/default/parchment_texture_v_roll_bottom.webp"); }

.card_cell p.unit-card-title {
  font-size: 20px;
  font-weight: bold;
  /* color: hsla(36, 93%, 41%, 1); */
  color: #000;
  font-family: serif;
  text-align: center;
  margin: 0 2px;
  padding: 16px 0px;
  position: relative; }

.card_cell .unit-card-title:before {
  content: "";
  height: 11px;
  width: 100%;
  position: absolute;
  top: 3px;
  left: 0;
  background: url("https://res.cloudinary.com/fishofstone/image/upload/f_auto,q_auto:low/twwstats/api/6009914752132233841/ui/skins/default/parchment_divider_title_bottom.webp");
  background-position: center;
  background-size: initial;
  background-repeat: no-repeat;
  transform: scaleY(-1); }

.card_cell .unit-card-title:after {
  content: "";
  height: 11px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: url("https://res.cloudinary.com/fishofstone/image/upload/f_auto,q_auto:low/twwstats/api/6009914752132233841/ui/skins/default/parchment_divider_title_bottom.webp");
  background-position: center;
  background-size: initial;
  background-repeat: no-repeat; }

.card_cell p.unit-card-header {
  font-size: 18px;
  font-weight: bold;
  color: #000;
  font-family: serif;
  margin: 0 2px;
  padding: 8px 0px;
  position: relative; }

#units-cards-container {
  display: grid;
  grid-template-rows: 32px auto;
  grid-template-columns: 1fr;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden; }

#units-cards-controls {
  grid-row: 1 / 2;
  grid-column: 1 / 2; }

#units-cards-cards-container {
  grid-row: 3 / 4;
  grid-column: 1 / 2;
  overflow: auto;
  justify-self: stretch; }

#units-cards-cards {
  display: grid;
  grid-column-gap: 12px; }

.cat_icon {
  position: relative;
  left: 1px;
  bottom: 29px; }

.cat_holder {
  width: 34px; }

.cat_holder_renown {
  bottom: 9px;
  width: 46px; }

.card_cell {
  color: #000;
  font-family: serif;
  font-size: 15px;
  font-weight: bold;
  width: 240px; }

.card_box {
  border: solid 1px #777;
  background-color: rgba(0, 0, 0, 0.1);
  margin: 2px;
  padding: 2px;
  display: flex; }

.card_box span {
  align-self: center; }

.card_button:hover {
  opacity: 1 !important; }

.card_cell .delta {
  color: #fff;
  padding: 2px 4px;
  margin-left: -17px;
  font-weight: normal;
  box-shadow: inset 9px 0 8px -8px #222;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-right: 1px solid; }

.delta_positive {
  background: #195b19;
  border-image: linear-gradient(to right, #222 0%, #2fac2f 15%) 100% 1; }

.delta_negative {
  background: #852228;
  border-image: linear-gradient(to right, #222 0%, #ce404a 15%) 100% 1; }

.delta:before {
  content: "";
  width: 0px;
  height: 0px;
  border-top: 12px solid transparent;
  border-bottom: 11px solid transparent;
  position: absolute;
  right: -11px;
  top: -1px; }

.delta:after {
  content: "";
  width: 0px;
  height: 0px;
  border-top: 11px solid transparent;
  border-bottom: 10px solid transparent;
  position: absolute;
  right: -10px;
  top: 0px; }

.delta_negative:before {
  border-left: 10px solid #ce404a; }

.delta_negative:after {
  border-left: 10px solid #852228; }

.delta_positive:before {
  border-left: 10px solid #2fac2f; }

.delta_positive:after {
  border-left: 10px solid #195b19; }

/* Dirty */
.basic-multi-select {
  color: black; }

.settings {
  margin-bottom: 16px; }

.settings p {
  font-style: italic;
  padding-left: 8px;
  margin: 0; }

.settings p:before {
  content: "*";
  padding-right: 4px; }

.color-green {
  color: #96ee34; }

.color-red {
  color: #ee2a2a; }

.color-magic {
  color: #41e1e1; }

.clickable {
  cursor: pointer; }

.clickable:hover {
  opacity: .8; }

/* .tippy-popper {
  max-width: 600px; } */
